import axios from "axios";
import { store } from "../redux/store/store";
import { config } from "./constants";
import { logout } from "../redux/features/authSlice";

const environmentName = 'production';
export const baseURL = config[environmentName].apiUrl;
const getStoreData = () => {
  const { accessToken } = store.getState().auth;
  return { accessToken };
};

const axiosInstance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
});

axiosInstance.interceptors.request.use(
  (config) => {
    const { accessToken } = getStoreData();
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const encodeQueryString = (params) => {
  const keys = Object.keys(params);
  const queryString = keys
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');
  return keys.length ? `?${queryString}` : '';
};


const handleRequest = async (method, URL, body) => {
  try {
    const response = await method(URL, body);
    return response;
  } catch (error) {
    const errorData = error.response
    if (errorData && errorData.status === 401) {
      store.dispatch(logout())
    }
    return error;
  }
};

export const fetchGETRequest = async (URL, body = {}) => {
  return handleRequest(axiosInstance.get, URL + encodeQueryString(body));
};

export const fetchPOSTRequest = async (URL, body = {}) => {
  return handleRequest(axiosInstance.post, URL, body);
};

export const fetchPUTRequest = async (URL, body = {}) => {
  return handleRequest(axiosInstance.put, URL, body);
};



